$ = require('jquery');

$(function() {

    // Allow contact form
    $("#versturen").attr("disabled", false);
    
    // Remove Anchors
    const menuBtn = $('.container-fluid');
    menuBtn.click(()=>{	
        setTimeout(()=>{
            removeHash();
        }, 1);
    });
    function removeHash(){
        history.replaceState('', document.title, window.location.origin + window.location.pathname + window.location.search);
    }
    
    // Header
    $(window).resize(function() {
        if($(window).width() >= 1140) {
            if($('.topnav-right').css('display') == 'none'){
                $('.topnav-right').css('display', 'block');
            }
        }
        else {
            if($('.topnav-right').css('display') == 'block'){
                $('.topnav-right').css('display', 'none');
            }
        }
    });
    $(".fa-bars").click(function() {
        $('.topnav-right').fadeToggle(200);
    });
    $(".header-links a").click(function() {
        if($(window).width() < 1140) {
            $('.topnav-right').fadeToggle(200);
        }
    });
    
    // Image box
    $('ul.modellen li img').click(function() {
        $('.img-box-foto').attr('src', 'img/modellen/' + $(this).attr('id') + '.jpg');
        $('.img-box').fadeToggle(200);
    });
    $('.img-box').click(function() {
        $('.img-box').fadeToggle(200);
    });
    
    // Contact form
    $("#versturen").click(function(){
        if( $("#naam").val() != ''){
            if( $("#email").val() != ''){
                if( $("#tel").val() != ''){
                    if( $("#omschrijving").val() != ''){
                        // Send email
                        $.ajax({
                            url:'mail.php',
                            method:'POST',
                            data:{
                                naam:$("#naam").val(),
                                email:$("#email").val(),
                                tel:$("#tel").val(),
                                omschrijving:$("#omschrijving").val(),
                                auto:$("#auto").val(),
                                type:$("#type").val(),
                                bouwjaar:$("#bouwjaar").val(),
                                kenteken:$("#kenteken").val(),
                            },
                            success:function(data){
                                console.log('Verzonden!');
                            // Show bedankt
                                $('.bedankt-box').fadeToggle(200);
                            // Empty input fields
                                $("#naam").val('');
                                $("#email").val('');
                                $("#tel").val('');
                                $("#omschrijving").val('');
                                $("#auto").val('');
                                $("#type").val('');
                                $("#bouwjaar").val('');
                                $("#kenteken").val('');
                            // Empty error placeholders
                                $("#omschrijving").attr("placeholder", "Omschrijving...");
                                $("#tel").attr("placeholder", "Telefoon nummer *");
                                $("#email").attr("placeholder", "Email *");
                                $("#naam").attr("placeholder", "Naam *");
                            // Empty errors
                                $("#omschrijving").removeClass('placeholder-error');
                                $("#tel").removeClass('placeholder-error');
                                $("#email").removeClass('placeholder-error');
                                $("#naam").removeClass('placeholder-error');
                            }
                        });
                    }
                    else { // omschrijving
                        $("#omschrijving").attr("placeholder", "Vul hier uw omschrijving in");
                        $("#omschrijving").addClass('placeholder-error');
                    }
                }
                else { // tel
                    $("#tel").attr("placeholder", "Vul hier uw telefoon nummer in");
                    $("#tel").addClass('placeholder-error');
                }
            }
            else { // email
                $("#email").attr("placeholder", "Vul hier uw email adres in");
                $("#email").addClass('placeholder-error');
            }
        }
        else { // naam
            $("#naam").attr("placeholder", "Vul hier uw naam in");
            $("#naam").addClass('placeholder-error');
        }
    });
    // Close contact
    $('#close-bedankt').click(function() {
        $('.bedankt-box').fadeToggle(200);
    });
});